<template>
  <div class="infoDetail">
      <div class="infoDetailContent">
        <banner style="padding-top:61px"></banner>
<!--        <div style="margin-bottom: 16px">-->
<!--          <el-button type="primary" plain size="small" @click="goList()">返回</el-button>-->
<!--        </div>-->
        <div style="margin-top: 20px">
          <el-card class="infoHtml">
            <div class="messageTitle">
              <h1>{{messageDetail.title}}</h1>
              <p>
                <span style="margin-right: 30px">发布日期:{{messageDetail.createTime}}</span>
                <span>浏览次数:{{newsDetailContent.glanceOvers}}</span>
              </p>
            </div>
            <div>
              <p v-html="newsDetailContent.content"></p>
            </div>
          </el-card>
          <div style="width: 342px">
            <el-card>
              <stdIntroduce ></stdIntroduce>
            </el-card>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import banner from './banner.vue'
import stdIntroduce from "@/views/vocationalExeam/stdIntroduce";
import {
  findContent,
} from "@/api";
  export default {
      components:{
        banner,
        stdIntroduce
      },
      data(){
        return{
          messageDetail:'',
          newsDetailContent:{}
        }
      },
      methods:{
      //  返回列表
        goList(){
          if(this.$route.query.newsIndex){
            this.$router.push('/vocationalExeam')
          }else{
            this.$router.push('/messageList')
          }
        },
      //  获取新闻详情
        getfindContent(){
          let data={
            id:this.messageDetail.id
          }
          findContent(data).then(res=>{
            this.newsDetailContent=res.data
            this.newsDetailContent.glanceOvers=res.data.glanceOver
          })
        }
      },
    mounted() {
        document.documentElement.scrollTop = 0;
        this.$other.$emit('setIndexNav',3);
        this.messageDetail=JSON.parse(sessionStorage.getItem('occupationNewsDetail'));
        this.getfindContent()
    }
  }
</script>
<style>
  .infoDetail{
    padding-bottom: 100px;
    background: #F7F7F7;
  }
  /*.infoDetail .el-carousel__container{*/
  /*  height: 620px;*/
  /*}*/
  .infoDetailContent{
    width: 1200px;
    margin: 0 auto;
    /*margin-top: 30px;*/
  }
  .infoHtml{
    width: 71%;
    margin-right: 10px;
  }
  .infoDetailContent>div{
    display: flex;
  }
  .messageTitle p{
    color: #999999;
    font-size: 12px;
  }
  .messageTitle h1{
    font-size: 22px;
    margin-bottom: 20px;
    white-space: normal;
  }

</style>
