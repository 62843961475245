<template>
  <div class="topicListBg">

    <div class="topicListContent">
      <banner style="padding-top: 61px"></banner>
      <div style="margin-top: 20px">
        <examRehearsal></examRehearsal>
      </div>
    </div>
  </div>
</template>
<script>
import banner from './banner.vue';
import examRehearsal from './examRehearsal.vue'
  export default {
    components:{
      banner,
      examRehearsal
    },
    data(){
      return{

      }
    },
    methods:{

    },
    mounted() {
      this.$other.$emit('setIndexNav',3);
    }
  }
</script>
<style>
  .topicListContent{
    width: 1200px;
    margin: 0 auto;
  }

</style>
