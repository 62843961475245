<template>
  <div class="vocationalBanner">
    <div class="homeBannerVocational">
      <el-carousel :interval="6000" arrow="always" indicator-position="none">
        <el-carousel-item v-for="(item,index) in bannerListArr" :key="index">
          <img :src="item.filePath" alt="" style="width: 100%;height: 100%"/>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
  import {bannerList} from "@/api";
  export default {
    name:'banner',
    data(){
      return{
        bannerListArr:[]
      }
    },
    methods:{
      //  获取banner
      getBanner() {
        let bannerData={
          fileCode: 'ZJGK_BANNER'
        }
        bannerList(bannerData).then(res => {
          this.bannerListArr = res.data.records
        })
      },
    },
    mounted() {
      this.getBanner()
    }
  }
</script>
<style>
  .vocationalBanner .homeBannerVocational{
    width: 100%;
    height: 276px;
  }
  .vocationalBanner .el-carousel__container img{
    object-fit: contain;
  }
</style>
