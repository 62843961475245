<template>
  <div class="messageListBg">
    <div class="messageListContent">
      <banner style="padding-top: 61px"></banner>
<!--      <div style="margin-bottom: 36px">-->
<!--        <city @cityName="cityName(arguments)"></city>-->
<!--      </div>-->
      <div style="margin-top:20px">
        <el-card class="messageWidth">
          <div class="messageTab">
            <p v-for="(item,index) in messageListTab" :key="index" @click="changeMessage(index)" :class="messageShow==index?'messageActive':''">{{item.name}}</p>
          </div>
          <div v-if="messageOne">
            <div v-for="(item,index) in newsListArr" :key="index" class="messageBt" >
              <div class="messageContentFlex" @click="goNewsDetail(item)">
                <div>
                  <h2 class="messageTitle">{{item.title}}</h2>
                  <div >
                    <p class="messageNewscontent" v-if="item.summary">{{item.summary}}</p>
                  </div>
                </div>
                <div class="messageImg">
                  <img :src="item.logo?item.logo:'images/index.png'" />
                </div>
              </div>
              <div class="messageFont">
                <span>e小智</span>
                <span>{{item.createTime}}</span>
              </div>
            </div>
            <div v-if="!newsListArr.length" style="width: 100%;min-height: 414px">
              <el-empty :image-size="200"></el-empty>
            </div>
          </div>
          <div v-if="messageTwo"  class="messageCourseFlex">
            <div class="messageDiv" v-for="(item,index) in VideoList" :key="index" @click="goVideoDetail(item)">
              <img :src="item.logo"/>
              <p class="messageDivP">{{item.name}}</p>
              <img src="images/paly.png" alt="" class="playVideos">
            </div>
            <div v-if="!VideoList.length" style="width: 100%">
              <el-empty :image-size="200"></el-empty>
            </div>
          </div>
          <div style="margin: 30px 0" v-if="!messageTwo&&messageShow!==5">
            <el-pagination
                class="text_center"
                background
                @current-change="handleNewsChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                layout="total,  prev, pager, next, jumper"
                :total="newsTotal"
            >
            </el-pagination>
          </div>
          <div style="margin: 30px 0" v-if="messageTwo&&messageShow==5">
            <el-pagination
                class="text_center"
                background
                @current-change="handleVideoChange"
                :current-page.sync="videoPage"
                :page-size="videoSize"
                layout="total,  prev, pager, next, jumper"
                :total="videoTotal"
            >
            </el-pagination>
          </div>
        </el-card>
        <div style="width: 342px">
          <el-card>
            <stdIntroduce ></stdIntroduce>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import banner from "@/views/vocationalExeam/banner";
  import stdIntroduce from "@/views/vocationalExeam/stdIntroduce";
  import {newsList, preparingVideoList} from "@/api";
  import city from "@/views/vocationalExeam/city";
  export default{
    components:{
      banner,
      stdIntroduce,
      city
    },
    data(){
      return{
        messageListTab:[
          {id:1,name:'政策文件'},
          {id:2,name:'头条新闻'},
          {id:3,name:'考纲解读'},
          {id:4,name:'招生信息'},
          {id:5,name:'备考资讯'},
          {id:6,name:'备考课程'},
        ],
        messageShow:0,
        messageOne:false,
        messageTwo:true,
        currentPage:1,
        pagesize:10,
        newsTotal:0,
        newsListArr:[],
        VideoList:[],
        cityTitle:'',
        videoTotal:0,
        videoPage:1,
        videoSize:10,
      }
    },
    methods:{
      changeMessage(index){
        this.messageShow=index;
        if(index==5){
          this.messageOne=false;
          this.messageTwo=true;
          this.getPreparingVideoList()
        }else{
          this.messageOne=true;
          this.messageTwo=false;
          this.getNewsList()
        };
      },
      //  获取新闻列表
      getNewsList(){
        let data={
          page:this.currentPage,
          size:this.pagesize,
          provinceName:''
        }
        if(this.messageShow==0){
          data['newsType']=4
        }else if(this.messageShow==1){
          data['newsType']=3
        }else if(this.messageShow==2){
          data['newsType']=5
        }else if(this.messageShow==3){
          data['newsType']=7
        }else if(this.messageShow==4){
          data['newsType']=6
        }
        if(this.cityTitle){
          data['provinceName']=this.cityTitle
        }
        newsList(data).then(res => {
          this.newsListArr=res.data.records;
          this.newsTotal=parseInt(res.data.total)
        })
      },
      //  获取备考课程列表
      getPreparingVideoList(){
        let data={
          page:this.videoPage,
          size:this.videoSize
        };
        preparingVideoList(data).then(res=>{
          this.VideoList=res.data.records;
          this.videoTotal=parseInt(res.data.total)
        })
      },
    //  去备考视频详情
      goVideoDetail(item){
        sessionStorage.setItem('videoDetails',JSON.stringify(item))
        let routeUrl = this.$router.resolve({
          path: "/courseVideo",
        });
        window.open(routeUrl.href);

      },
      //  去新闻详情
      goNewsDetail(item){
        sessionStorage.setItem('occupationNewsDetail',JSON.stringify(item));
        let routeUrl = this.$router.resolve({
          path: "/informationDetail",
        });
        window.open(routeUrl.href);
      },
      //切换省份
      cityName(e){
        this.cityTitle=e[0];
        this.getNewsList()
      },
    //  新闻分页
      handleNewsChange(currentPage){
        this.currentPage=currentPage;
        this.getNewsList()
      },
    //  课程视频分页
      handleVideoChange(videoPage){
        this.videoPage=videoPage;
        this.getPreparingVideoList()
      }
    },
    mounted() {
      document.documentElement.scrollTop = 0;
      this.$other.$emit('setIndexNav',3);
      if(this.$route.query.tabIndex){
        this.messageShow=this.$route.query.tabIndex;
      }
      if(this.$route.query.tabIndex&&this.$route.query.tabIndex==5){
        this.messageOne=false;
        this.messageTwo=true;
        this.getPreparingVideoList()
      }else{
        this.messageOne=true;
        this.messageTwo=false
      }
      this.getNewsList()
    }

  }
</script>
<style>
  .messageListBg{
    padding-bottom: 100px;
    background: #F7F7F7;
  }
  /*.messageListBg .el-carousel__container{*/
  /*  height: 620px;*/
  /*}*/
  .messageListContent{
    width: 1200px;
    margin: 0 auto;
    /*margin-top: 30px;*/
  }
  .messageWidth{
    width: 70%;
    margin-right: 12px;
  }
  .messageListContent>div,.messageContentFlex{
    display: flex;
  }

  .messageContentFlex>div:first-child{
    width: 76%;
  }
  .messageNewscontent h1{
    height: 60px;
  }
  .messageImg>img{
    width: 138px;
    height: 94px;
    object-fit: contain;
  }
  .messageTitle{
    margin-bottom: 20px;
    width: 100%;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .messageNewscontent{
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
  }
  .messageFont{
    font-size: 12px;
    color: #999999;
  }
  .messageFont>span:first-child{
    margin-right: 30px;
  }
  .messageBt{
    /*margin-bottom: 50px;*/
    cursor: pointer;
    padding: 20px;
  }
  .messageBt:hover{
    background: #f6f6f6;
  }
  .messageTab{
    display: flex;
    margin: 15px 0 30px 0;
  }
  .messageTab>p{
    margin-right: 20px;
    cursor: pointer;
    /*font-size: 20px;*/
    padding-bottom: 10px;
  }
  .messageActive{
    /*border-bottom: 2px solid #49B151;*/
    color: #409EFF;
    font-weight: bold;
  }
  .messageDiv{
    background: white;
    box-shadow: 0px 0px 7px 0px rgba(91, 91, 91, 0.32);
    width: 180px;
    border-radius: 4px;
    margin-bottom: 20px;
    position: relative;
    transition: 0.5s all;
    cursor: pointer;
    margin-right: 20px;
    height: 160px;
  }
  .messageDiv>img:first-child{
    width: 100%;
    height: 116px;
    object-fit: cover;
  }
  .messageDiv>p{
    padding: 10px;
    /*width: 92%;*/
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }
  .messageCourseFlex{
    display: flex;
    flex-wrap: wrap;
    min-height: 414px;
    /*justify-content: space-between;*/
  }
  .playVideos{
    position: absolute;
    top: 38px;
    left: 66px;
    display: none;
  }
  .messageDiv:hover .playVideos{
    display: block;
  }
  .messageDiv:hover .messageDivP{
    color: #49B151;
    font-weight: bold;
  }
</style>
