<template>
  <div  >
<!--    <userHead></userHead>-->
    <div style="margin-top: 20px">
      <el-card shadow="always"  v-if="!orderDetailshow">
        <div  slot="header">
  <!--        <div class="myOrderFlex">-->
  <!--          <p v-for="(item,index) in orderArr":key="index" @click="changeOrderArr(index)" :class="orderActiveShow==index?'orderActive':''">{{item.name}}</p>-->
  <!--        </div>-->
          <el-row>
            <el-col :span="20">
              <el-menu style="width: 100% !important;" :default-active="selfCourseIsOverdue"  mode="horizontal" @select="selectOpen">
                <el-menu-item index="0">全部订单</el-menu-item>
                <el-menu-item index="1">待付款</el-menu-item>
                <el-menu-item index="2">已完成</el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div>
        <div v-for="(item,index) in orderListArr" :key="index">
          <div class="myOrderCourseContentFlex" v-for="(item1,index1) in item.orderItemList" :key="index1">
            <div class="myOrderCourseFlex" >
              <img class="myOrderLogo" v-if="item1.goodsType!=='VIP'" :src="item1.courseInfo&&item1.courseInfo.logo?item1.courseInfo.logo:item1.coverPic" />
              <img class="myOrderLogo" v-if="item1.goodsType=='VIP'" src="/images/vipImg.png" />
              <div>
                <p>{{item1.courseInfo&&item1.courseInfo.name?item1.courseInfo.name:item1.goodsName}}</p>
      <!--          <p class="myOrderCourseTeacher">主讲教师：张斌</p>-->
              </div>
            </div>
            <div class="myOrderCourseFlex">
              <p v-if="item.orderStatus=='WAITPAY'">待付款</p>
              <p v-if="item.orderStatus=='PAID'">已完成</p>
              <div class="myOrderPrice">
                <p>价格:¥{{item1.price}}</p>
      <!--          <p>原价:88</p>-->
              </div>
              <el-button round v-if="item.orderStatus=='WAITPAY'" @click="promptlyPayment(item.id)">立即付款</el-button>
  <!--            <el-button round v-if="item.orderStatus=='PAID'" @click="goOrderDetail(item.orderItemList)">订单详情</el-button>-->
              <el-button round  @click="goOrderDetail(item)">订单详情</el-button>
            </div>
          </div>
        </div>
        <div style="margin: 30px 0">
          <el-pagination
              class="text_center"
              background
              @current-change="handleCourseChange"
              :current-page.sync="coursePage"
              :page-size="courseSize"
              layout="total,  prev, pager, next, jumper"
              :total="courseTotal"
          >
          </el-pagination>
        </div>
      </el-card>
      <div v-if="orderDetailshow">
        <div class="listFan">
          <el-button type="primary" plain @click="retrnList()">返回列表</el-button>
        </div>
        <div>
          <p class="myOrderPricest" v-if="orderStatus=='WAITPAY'">
            <span>待付款</span>
            <span>¥{{payAmount}}</span>
          </p>
          <p class="myOrderPricest" v-if="orderStatus=='PAID'">
            <span>已付款</span>
            <span> ¥{{payAmount}}</span>
          </p>
          <p class="orderIdClass">订单号：{{orderItemList.orderId}}</p>
        </div>
        <div>
          <p class="myorderTitle">{{orderItemList.courseInfo.name}}</p>
        </div>
      </div>
      <el-dialog :visible.sync="paymentWindow" title="请选择支付方式">
        <div style="padding: 0 0 100px 0;">
          <div class="orderRadioFlex">
            <el-radio v-model="Payradio" label="1">
              <div class="orderRadio">
                <img src="images/wxPay.png" />
                <span>微信</span>
              </div>
            </el-radio>
            <el-radio v-model="Payradio" label="2">
              <div class="orderRadio">
                <img src="images/Alipay.png" />
                <span>支付宝</span>
              </div>
            </el-radio>
          </div>
          <div style="display: flex;justify-content: center">
            <el-button type="primary" @click="promptlyBuy">立即支付</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog :title="(Payradio===1?'支付宝':'微信') +'支付'" :visible.sync="qrcodeShow" width="250px" :destroy-on-close="true" @opened="openQrCodeImg" >
        <span class="codeimg" ref="qrcodeImg"></span>
      </el-dialog>
      <div ref="aliPay"></div>
    </div>
  </div>
</template>
<script>
import userHead from './userHead'
import {createCourseOrVipOrder, orderList, payAliMoney, payMoney} from "@/api";
import QRCode from "qrcodejs2";
  export default {

    data(){
      return{
        orderArr:[
          {id:1,name:'全部订单'},
          {id:2,name:'待付款'},
          {id:3,name:'已完成'},
        ],
        selfCourseIsOverdue:"0",
        orderActiveShow:0,
        coursePage:1,
        courseSize:10,
        courseTotal:10,
        orderListArr:[],
        orderDetailshow:false,
        paymentWindow:false,
        Payradio:'1',
        qrcodeShow: false,
        orderId:'',
        orderItemList: {},
        orderStatus:'',
        payAmount:''
      }
    },
    methods:{
      getOrderList(){
        let userInfo=JSON.parse(localStorage.getItem('userInfo'))
        let data={
          orderStatus:'',
          userId:userInfo.id,
          terminalType:'WEB',
          page:this.coursePage,
          size:this.courseSize
        };
        if(this.selfCourseIsOverdue==1){
          data['orderStatus']='WAITPAY'
        }else if(this.selfCourseIsOverdue==2){
          data['orderStatus']='PAID'
        }
        orderList(data).then(res=>{
          this.orderListArr=res.data.records;
          this.courseTotal=JSON.parse(res.data.total)
        })
      },
      handleCourseChange(coursePage){
        this.coursePage=coursePage;
        this.getOrderList()
      },
    //  去订单详情
      goOrderDetail(item){
        this.orderDetailshow=true;
        this.orderItemList=item.orderItemList[0];
        this.orderStatus=item.orderStatus;
        this.payAmount=item.payAmount
      },
    //  立即付款弹窗
      promptlyPayment(id){
        this.paymentWindow=true;
        this.orderId=id
      },
      //  创建订单支付
      promptlyBuy(){
        if(this.Payradio==1){
          this.wxPay(this.orderId);
        }else{
          this.Alipay(this.orderId)
        }
      },
      //微信支付
      wxPay(orderId) {
        let data={
          terminalType:'PC',
          orderId:orderId,
          payType:'WX'
        };
        payMoney(data).then(res=>{
          if(res.code==0){
            this.qrcodeImg =JSON.parse(res.data).code_url;
            this.qrcodeShow = true;
          }
        })
      },
      //支付宝支付
      Alipay(orderId){
        let data={
          terminalType:'PC',
          orderId:orderId,
          payType:'ALI'
        };
        payAliMoney(data).then(res=>{
          this.$refs.aliPay.innerHTML = res;
          document.getElementsByName('punchout_form')[0].submit()
        })
      },
      // 生成二维码
      openQrCodeImg(){
        new QRCode(this.$refs.qrcodeImg, {
          width: 200,
          height: 200,
          text: this.qrcodeImg,
          render: 'canvas'
        });
      },
      //返回列表
      retrnList(){
        this.orderDetailshow=false;
      },
      selectOpen(index, indexPath){
        this.selfCourseIsOverdue = index;
        this.coursePage=1
        this.getOrderList();
      },
    },
    mounted() {
      this.getOrderList()
    },
    components:{
      userHead
    },
  }
</script>
<style scoped>
@import "../../../../public/css/myOrder.css";
</style>
