<template>
  <div class="workPublishBg">
    <div class="workPublishPad">
      <div>
        <p class="publishWork">发布作业信息填写</p>
      </div>
      <div class="workWriteContent">
        <div>
          <span>作业标题</span>
          <el-input v-model="taskTitle" placeholder="请填写标题"></el-input>
        </div>
        <div>
          <span>所属课程</span>
          <template>
            <el-select v-model="courseValue" placeholder="请选择" @change="changeCourse()">
              <el-option
                  v-for="item in schoolCourseLIstArr"
                  :key="item.platformCourseInfo.id"
                  :label="item.platformCourseInfo.name"
                  :value="item.platformCourseInfo.courseItemList">
              </el-option>
            </el-select>
          </template>
        </div>
        <div>
          <span>提交时间</span>
          <el-date-picker
              v-model="submitTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div>
          <span>发布对象</span>
          <template>
            <el-select v-model="classCascader" multiple placeholder="请选择" >
              <el-option
                  v-for="item in classGradeCas"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </template>
        </div>
      </div>
      <div class="addWorkDistrict">
        <div class="addWorkBtnFlex">
          <el-button  type="primary" @click="addDomain">新增作业</el-button>
          <el-button @click="examintionChoose">题库中选择</el-button>
        </div>
        <div>
          <!--          选中的试题列表-->
          <div class="pitchQuestuinList" >
            <div class="topicOveflow" v-for="(item,index) in chooseQuestionList" :key="index">
              <div class="topicBorders">
                <div style="margin-bottom: 10px">
                  <el-tag>{{ index + 1 }}</el-tag>
                </div>
                <!--                  新增作业题triangle-toprightOver-->
                <div v-if="!item.id" class="addWorkSAQFlex">
                  <el-input type="textarea"
                            :autosize="{ minRows: 4, maxRows: 12}"
                            placeholder="请输入内容"
                            v-model="item.courseworkItemTitle"></el-input>
<!--                  <el-button @click.prevent="removeDomain(item)">删除</el-button>-->
                </div>
                <p class="topicTitle">
                  <span>{{ item.exercisesTitle }}</span>
                </p>
                <div>
                  <!-- 选择题-->
                  <div v-if="item.exercisesType=='SC' || item.exercisesType=='MC'">
                    <div v-for="(item1, index1) in item.questionOption"
                         :key="index1"
                         class="chooseSpan">
                      <div>
                        {{ getLetter(index1) }}：{{ item1 }}
                      </div>
                    </div>
                  </div>
                  <!-- 判断题-->
                  <div v-if="item.exercisesType=='TF'">
                    <el-radio v-model="chooseRadio" disabled label="选中且禁用">正确</el-radio>
                    <el-radio v-model="chooseRadio" label="2" disabled>错误</el-radio>
                  </div>
                  <!-- 简答题-->
                  <div v-if="item.exercisesType=='SAQ'">
                    <el-input
                        type="textarea"
                        disabled
                        v-model="correctDesc"
                        :autosize="{ minRows: 4, maxRows: 10 }"
                    ></el-input>
                  </div>
                </div>
              </div>
              <img  src="images/deleteQustion.png" class="deleteQuestionIMg" @click="deleteChooseQuestion(index,item)"/>
            </div>
          </div>
        </div>
      </div>
      <div class="affrimWorkFlex" @click="affrimPublishTask()">
        <el-button type="primary">确认</el-button>
      </div>
    </div>
    <!--    添加试题弹窗-->
    <el-dialog :visible.sync="ExaminationWindowShow" :show-close="true" width="76%">
      <div>
        <div class="examinationFlexsd">
          <div>
            <el-menu
                default-active="1"
                class="el-menu-vertical-demo"
            >
              <el-submenu v-for="(item, index) in courseDetailArr"
                          :key="index" :index="index + 1 + ''" >
                <template slot="title">
                  <span class="courseZjWid">{{ item.name }}</span>
                </template>
                <el-menu-item-group
                    v-for="(itemChildren, indexChildren) in item.children"
                    :key="indexChildren"
                >
                  <el-menu-item :index="index + '-' + indexChildren">
                    <div slot="title" @click="chooseChilrden(itemChildren)">
                      <span class="courseZjWid" >{{ itemChildren.name }}</span>
                      <!-- <span class="trySee">试看</span> -->
<!--                      <img v-if="itemChildren.freeData==0" src="images/lock.png" alt="" class="lock"/>-->
                    </div>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </div>
          <!--          试题-->
          <div>
            <div class="questionsFlexSelect">
              <template>
                <el-select v-model="questionsTypeValue" placeholder="请选择">
                  <el-option
                      v-for="item in questionsTypeArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </template>
              <div class="questionsTypeMr">
                <el-select v-model="questionsStarValue" placeholder="请选择">
                  <el-option
                      v-for="item in questionsStarArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name">
                  </el-option>
                </el-select>
              </div>
              <div>
                <p class="searchType" @click="getcourseXiti()">搜索</p>
              </div>
            </div>
            <!--            试题列表-->
            <div class="topicOveflow" v-for="(item,index) in questionList" :key="index" @click="chooseQuestion(item)">
              <div class="topicBorders">
                <p class="topicTitle" :class="topicArr.includes(item.id)?'tab-active':'topicTitle'">
                  <span>{{ index + 1 }}.</span>
                  <span>{{ item.exercisesTitle }}</span>
                </p>
                <exercisesEnclosure :fileUrl="item.exercisesAccessoryUrl"></exercisesEnclosure>
                <div>
                  <!-- 选择题-->
                  <div v-if="item.exercisesType=='SC' || item.exercisesType=='MC'">
                    <div v-for="(item1, index1) in item.questionOption"
                         :key="index1"
                         class="chooseSpan">
                      <div>
                        {{ getLetter(index1) }}：{{ item1 }}
                      </div>
                    </div>
                  </div>
                  <!-- 判断题-->
                  <div v-if="item.exercisesType=='TF'">
                    <el-radio v-model="chooseRadio" disabled label="选中且禁用">正确</el-radio>
                    <el-radio v-model="chooseRadio" label="2" disabled>错误</el-radio>
                  </div>
                  <!-- 简答题-->
                  <div v-if="item.exercisesType=='SAQ'">
                    <el-input
                        type="textarea"
                        disabled
                        v-model="correctDesc"
                        :autosize="{ minRows: 4, maxRows: 10 }"
                    ></el-input>
                  </div>
                  <!--                  填空题-->
                  <div v-if="item.exercisesType=='FB'">
                    <div v-for="i in item.exercisesBody" :key="i">
                      <el-input disabled class="tiankongInput"></el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 30px;">
              <el-pagination
                  class="text_center"
                  background
                  @current-change="handleworkList"
                  :current-page.sync="workPage"
                  :page-size="worksize"
                  layout="total,  prev, pager, next, jumper"
                  :total="worktotal"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div class="submitAddQuestion" @click="addAccomplish">
          <p>添加完成</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {classNameList, courseList, addTask, operationTopic, audioImg, classList,pigeonholeCourse} from "@/api";
import exercisesEnclosure from '@/components/exercisesList/exercisesEnclosure'
export default {
  components: {
    exercisesEnclosure,
  },
  data() {
    return {
      questionList: [],
      submitTime: "",
      courseValue: "",
      schoolCourseLIstArr: [],
      classCascader: [],
      classGradeCas: [],
      dynamicValidateForm: {
        domains: [{
          courseworkItemTitle: '',
        }],
      },
      ExaminationWindowShow: false,
      courseDetailArr: [],
      chooseQuestionList: [],
      worktotal: 0,
      workPage: 1,
      worksize: 4,
      questionsTypeValue: "",
      questionsStarValue: "",
      questionsTypeArr: [
        {id: 1, name: "单项选择题", value:'SC'},
        {id: 2, name: "多项选择题", value:'MC'},
        {id: 3, name: "判断题", value:'TF'},
        {id: 4, name: "填空题", value:'FB'},
        {id: 5, name: "简答题", value:'SAQ'},
      ],
      questionsStarArr: [
        {id: 1, name: '1星'},
        {id: 2, name: '2星'},
        {id: 3, name: '3星'},
        {id: 4, name: '4星'},
        {id: 5, name: '5星'},
      ],
      topicArr: [],
      chooseRadio: '',
      //  选择课程
      courseItemList:[],
      taskTitle:"",
      setChooseQuestionList:[],
      correctDesc:''
    }
  },
  methods: {
    getLetter(index) {
      return String.fromCharCode(65 + index)
    },
    //  数组去重
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    },
    //  删除选中的试题
    deleteChooseQuestion(index,item){
      this.chooseQuestionList.splice(index,1);
      this.setChooseQuestionList.splice(index,1);
      let index1 = this.topicArr.indexOf(item);
      if (index1) {
        this.topicArr.splice(index, 1);
      }
      // this.topicArr.splice(item,1);
    },
    //新增作业
    addDomain() {
      let addmomainData={
        courseworkItemTitle:'',
        workType:'SAQ'
      }
      this.chooseQuestionList.push(addmomainData);
    },
    //删除作业
    removeDomain(item) {
      var index = this.chooseQuestionList.indexOf(item)
      if (index !== -1) {
        this.chooseQuestionList.splice(index, 1)
      }
    },
    //题库中选择
    examintionChoose() {
      if(!this.courseValue){
        this.$message('请选择课程'); return
      }
      this.ExaminationWindowShow = true;
      this.getCourseDetailClassify()
    },
    //添加完成
    addAccomplish() {
      this.ExaminationWindowShow = false;
      this.questionsTypeValue = ""
      this.questionsStarValue = "";
      this.setChooseQuestionList.forEach(item=>{
        let arr = true;
        this.chooseQuestionList.forEach(questionItem=>{
          //无id为作业,不进行判断
          if(questionItem.id&&questionItem.id==item.id){
            arr = false;
          }
        })
        if(arr){
          this.chooseQuestionList.push(item);
        }
        // let arr1 = this.unique(this.chooseQuestionList);
        // this.chooseQuestionList=arr1;
      });
    },
    //  获取课程列表
    getCourseAdministrationList() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data = {
        page:1,
        size: 100,
        teacherId:userInfo.id,
        isFile:false
      }
      pigeonholeCourse(data).then(res => {
        this.schoolCourseLIstArr=res.data.records;
      })
    },
    //获取年级名称列表
    getGradeList() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data = {
        schoolId: userInfo.schoolId,
        page:1,
        size:100
      }
      classList(data).then(res => {
        this.classGradeCas = res.data.records;
      })
    },
    //选择年级
    async handleChangeGreade(node, resolve) {
      const {level, value} = node;
      let data = {
        page: 1,
        size: 100,
        classGrade: value
      }
      let res = await classNameList(data)
      if (!res || !res.data || !res.data.records) return;
      const nodes = [];
      res.data.records.forEach((item) => {
        nodes.push({
          classGrade: item.name ? item.name : '暂无名称',
          leaf: level >= 1
        })
      })
      // 通过调用resolve将子节点数据返回，通知组件数据加载完成
      resolve(nodes);
    },
    //选择试题
    chooseQuestion(item) {
      let index = this.topicArr.indexOf(item.id);
      if (index !== -1) {
        this.topicArr.splice(index, 1);
        this.setChooseQuestionList.splice(item,1);
        // this.newsChooseQuestionList.splice(item,1)
        return;
      }
      this.topicArr.push(item.id);
      let questions={...item}
      this.setChooseQuestionList.push(questions);
    },
    //  获取课程详情树形结构
    getCourseDetailClassify() {
      let courseItemList = this.courseItemList
      const listToTree = (listArr, pid) => {
        let tree = [];
        listArr.forEach((item, index) => {
          if (item.pid === pid) {
            let child = listToTree(courseItemList, item.id)
            let data = {...item}
            child && child.length && (data['children'] = child)
            tree.push(data)
          }
        })
        return tree;
      }
      this.courseDetailArr = listToTree(courseItemList, '0');
    },
    chooseChilrden(item) {
      this.courseInfoDetail = item
      this.getcourseXiti()
    },
    //习题列表
    getcourseXiti() {
      let query = {
        platformCourseId: this.courseInfoDetail.platformCourseId,
        platformCourseItemId: this.courseInfoDetail.id,
        page: this.workPage,
        size: this.worksize,
        sourceDiff: '',
        exercisesType: this.questionsTypeValue
      }
      if (this.questionsStarValue) {
        query['starNumber'] = this.questionsStarValue.slice(0, 1)
      }

      operationTopic(query).then(res => {
        this.worktotal = parseInt(res.data.total);
        this.questionList = res.data.records;
        res.data.records.forEach(async (item, index) => {
          if(item.exercisesBody){
            item.questionOption = item.exercisesBody.split('\@')
          }
          if (item.correctAnswer) {
            item.correctAnswerArr = item.correctAnswer.split(',')
          }
          if (item.exercisesType === 'FB') {
            item.exercisesBody = JSON.parse(item.exercisesBody)
          }
          if (item.exercisesAccessoryUrl) {
            let exercisesAccessoryUrl = item.exercisesAccessoryUrl.split(",")
            let query = {
              queryDataList: []
            }
            exercisesAccessoryUrl.forEach((item1) => {
              query.queryDataList.push({thirdPartyStorageId: item1})
            })
            let sources = await audioImg(query)
          }
        })

      })
    },
    //习题分页
    //  习题作业分页
    handleworkList(workPage) {
      this.workPage = workPage;
      this.getcourseXiti()
    },

    //  选择课程
    changeCourse() {
      this.courseItemList = this.courseValue;
    },
    changeCascader(){
    },
  //  发布作业
    affrimPublishTask(){
      if(this.taskTitle==""){
        this.$message("作业标题不可为空");
        return
      }
      if(this.courseValue==""){
        this.$message("请选择所属课程");
        return
      }
      if(this.submitTime === ""){
        this.$message("请选择时间");
        return
      }
      if(this.classCascader === ""){
        this.$message("请选择发布对象");
        return
      }

      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data={
        schoolId:userInfo.schoolId,
        teacherId:userInfo.id,
        platformCourseId:this.courseValue[0].platformCourseId,
        startTime:this.submitTime[0],
        endTime:this.submitTime[1],
        pushClassIds:this.classCascader,
        name:this.taskTitle,
        items:[]
      }
      // if(this.dynamicValidateForm.domains&&this.dynamicValidateForm.domains.length&&!this.chooseQuestionList.length){
      //   this.dynamicValidateForm.domains.forEach(item1=>{
      //     let domainsData= {
      //       courseworkItemTitle: "",
      //       workType:"SAQ"
      //     }
      //     domainsData.courseworkItemTitle=item1.courseworkItemTitle
      //     data['items'].push(domainsData)
      //   });
      // }
      if(this.chooseQuestionList&&this.chooseQuestionList.length){
        this.chooseQuestionList.forEach(item=>{
          let questionData={}
          if(item.id){
            questionData['courseworkItemTitle']=item.exercisesTitle;
            questionData['exercisesId']=item.id;
            questionData['workType']=item.exercisesType
            data['items'].push(questionData)
          }
          if(!item.id){
            questionData['courseworkItemTitle']=item.courseworkItemTitle;
            questionData['workType']=item.workType;
            data['items'].push(questionData)
          }
        })
      }
      addTask(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: '发布成功',
            type: 'success'
          });
          this.$router.push({path:"/classCenter"});
          sessionStorage.setItem('publishWorkId',"4")
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  },
  mounted() {
    this.getCourseAdministrationList();
    this.getGradeList();
    this.$other.$emit('setIndexNav',1);
  }
}
</script>
<style scoped>
@import "../../../../public/css/workPublish.css";
</style>
