<template>
  <div class="vocationalExeam">
    <div class="vocationalContent">
      <banner style="padding-top: 61px"></banner>
<!--      <div style="margin-bottom: 36px">-->
<!--        <city @cityName="cityName(arguments)"></city>-->
<!--      </div>-->
<!--      -->
      <div class="vocationalNewsOne">
<!--        头条新闻-->
        <div>
          <div>
            <div >
<!--              <vue-aliplayer-v2 ref="VueAliplayerV2" :options="videoOptions" />-->
              <div @click="changeOpenVideo" class="vocationindexVideoImg">
                <img :src="filePath" >
                <img src="images/playVideo.png">
              </div>
            </div>
          </div>
          <div class="newsTitleCard">
            <el-card class="box-card">
              <div  class="vocationalnewsTitletotal">
                <p>头条新闻</p>
                <span @click="goNewsmoreList(1)">更多</span>
              </div>
              <div class="vocationalNewsFlex"  v-for="(item,index) in headNewsList.slice(0, 2)" :key="index">
                <img :src="item.logo?item.logo:'images/zanwuImgs.png'"/>
                <div class="vocationalNewsheadline" @click="goNewsDetail(item)">
                  <h3>{{item.title}}</h3>
                  <p class="vocationalSummary" v-if="item.summary">{{item.summary}}</p>
                </div>
              </div>
            </el-card>
          </div>
        </div>
<!--        综合测试-->
        <div class="realExeamPaper">
          <el-card>
            <div  class="vocationalnewsTitle">
              <div class="vocationalnewsTitleTab">
                <p v-for="(item,index) in examinationArr" :key="index" :class="examShow==index?'examinationActive':'vocationalnewsTitleExam'" @click="changeExam(index)">{{item.name}}</p>
              </div>
              <span @click="goTopicList()">更多</span>
            </div>
            <div class="doExercise" v-for="(item,index) in collegeTemplateListArr" :key="index" v-show="examShow==0">
              <p>{{item.name}}</p>
              <template v-if="userInfo">
                <el-button type="primary"  v-show="userInfo&&!userInfo.teacherType"  size="mini" @click="startExamination(item)">开始做题</el-button>
                <el-button type="primary" size="mini" v-show="userInfo&&userInfo.teacherType"  @click="selectExamination(item)">查看试卷</el-button>
              </template>
            </div>
            <div v-show="examShow==1">
              <el-menu
                  :default-active="defaultActive"
                  class="el-menu-vertical-demo"
                  ref="userCenterMenu"
              >
                <el-submenu
                    v-for="(item, index) in courseDetailArr"
                    :key="index"
                    :index="item.id?item.id:''">
                  <template slot="title">
                    <span class="jieTitle">{{ item.name }}</span>
                  </template>
                  <el-menu-item-group
                      v-for="(itemChildren, indexChildren) in item.children"
                      :key="indexChildren"
                  >
                    <el-menu-item :index="itemChildren.id+''">
                      <div slot="title" @click="startExamination(itemChildren)" >
                        <span >{{ itemChildren.name }}</span>
                      </div>
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
              </el-menu>
            </div>
          </el-card>
        </div>
<!--        学生简介-->
        <el-card class="stdrealExeamPaper">
          <stdIntroduce></stdIntroduce>
        </el-card>
      </div>
      <div  >
<!--        <img src="images/b3.png" class="newsBackgroundBg"/>-->
        <div class="vocationalCourseFlex">
          <img v-for="(item,index) in courserListArr.slice(0,6)" :key="index" @click="goCourseDetail(item)" :src="item.logo"/>
        </div>
      </div>
<!--      three-->
      <div class="newsFlexbig">
        <div class="vocationalNewsWidthFlex">
<!--          政策信息-->
          <div class="vocationalNewsWidth" >
            <el-card>
              <div class="vocationalnewsTitle">
                <p class="vocationalnewsTitlep">政策文件</p>
                <span @click="goNewsmoreList(0)">更多</span>
              </div>
              <div class="newsTitlemessageFlex" v-for="(item,index) in policyInfoList.slice(0, 5)" :key="index" @click="goNewsDetail(item)">
                <img src="images/newsIcon.png"/>
                <span>{{item.title}}</span>
                <span>{{item.createTime}}</span>
              </div>
            </el-card>
          </div>
          <!--          考纲解读-->
          <div class="vocationalNewsWidth" >
            <el-card>
              <div class="vocationalnewsTitle">
                <p class="vocationalnewsTitlep">考纲解读</p>
                <span @click="goNewsmoreList(2)">更多</span>
              </div>
              <div class="newsTitlemessageFlex" v-for="(item,index) in examinationUnscrambleList.slice(0, 5)" :key="index" @click="goNewsDetail(item)">
                <img src="images/newsIcon.png"/>
                <span>{{item.title}}</span>
                <span>{{item.createTime}}</span>
              </div>
            </el-card>
          </div>
          <!--          招生政策-->
          <div class="vocationalNewsWidth" >
            <el-card>
              <div class="vocationalnewsTitle">
                <p class="vocationalnewsTitlep">招考信息</p>
                <span @click="goNewsmoreList(3)">更多</span>
              </div>
              <div class="newsTitlemessageFlex" v-for="(item,index) in recruitNewsList.slice(0, 5)" :key="index" @click="goNewsDetail(item)">
                <img src="images/newsIcon.png"/>
                <span>{{item.title}}</span>
                <span>{{item.createTime}}</span>
              </div>
            </el-card>
          </div>
          <div class="vocationalNewsWidth">
            <el-card>
              <div class="vocationalnewsTabTitle">
                <div class="vocationalnewsTab">
                  <p v-for="(item,index) in vocationalNewsNav" :key="index" @click="changeVocationNews(index)" :class="newsNavShow==index?'vocationalnewsTabActive':'vocationalnewsTabP'">{{item.name}}</p>
                </div>
                <span @click="goNewsmoreListCourse(newsNavShow)" style="cursor: pointer;font-size: 14px">更多</span>
              </div>
              <div class="newsTitlemessageFlex" v-if="newsNavShow==1" v-for="(item1,index1) in referenceList.slice(0, 5)" :key="index1" @click="goNewsDetail(item1)">
                <img src="images/newsIcon.png"/>
                <span>{{item1.title}}</span>
                <span>{{item1.createTime}}</span>
              </div>
              <div v-if="newsNavShow==0" class="setoutCourseVideo">
                <div >
                  <div v-for="(items,indexs) in VideoList" :key="indexs" >
                    <div @click="goVideoDetail(items)" style="cursor: pointer;">
                      <img :src="items.logo" class="setoutCourseVideoImg"/>
                      <p>{{items.name}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <div>
          <div style="margin-left: 10px;width: 361px" class="recentlyAnnounceBg">
            <el-card >
              <p class="recentlyAnnounce" v-for="(item,index) in newsListArr.slice(0, 15)" :key="index" @click="goNewsDetail(item)">
                <span>[最近发表]</span>
                <span class="newsTitleLate" :class="item.colorShow?'newActiveColor':''">{{item.title}}</span>
                <img v-if="item.colorShow" src="images/hot.gif">
              </p>
            </el-card>
          </div>
        </div>
      </div>

    </div>
    <div style="margin-top: 80px">
      <Footer></Footer>
    </div>
    <el-dialog
        :visible.sync="videoWhindow"
        :show-close="true"
        :append-to-body="true"
        :destroy-on-close="true">
      <div class="resourceImgas" v-if="videoWhindow">
        <vue-aliplayer-v2   ref="VueAliplayerV2" :options="videoOptions" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import stdIntroduce from './stdIntroduce.vue';
  import city from './city.vue'
  import banner from './banner.vue'
  import {
    bannerList,
    collegeEntranceTemplateList,
    courseList,
    homeVideoInfo,
    newsList,
    preparingVideoList,
    subjectList,

  } from "@/api";
  import VueAliplayerV2 from 'vue-aliplayer-v2';
  import Footer from '../footer.vue'
  export default{
    components:{
      stdIntroduce,
      banner,
      city,
      VueAliplayerV2,
      Footer
    },
    data(){
      return{
        bannerList: [],
        newsTitleArr:[
          {id:1,name:'政策文件'},
          {id:2,name:'考纲解读'},
          {id:3,name:'考纲解读'},
        ],
        vocationalNewsNav:[
          {id:1,name:"备考课程"},
          {id:2,name:"备考资讯"},
        ],
        newsNavShow:0,
        currentPage:1,
        pagesize:100,
        headNewsList:[],
        examinationUnscrambleList:[],
        referenceList:[],
        recruitNewsList:[],
        policyInfoList:[],
        cityTitle:'',
        newsListArr:[],
        VideoList:[],
        videoOptions: {},
        collegeTemplateListArr:[],
        courserListArr:[],
        videoWhindow:false,
        filePath:'',
        examinationArr:[
          {id:1,name:'考前冲刺'},
          {id:2,name:'单元测试'}
        ],
        examShow:0,
        defaultActive:'1',
        courseDetailArr:[]
      }
    },
    methods:{
      //获取学科列表
      getsubjectList(){
        subjectList().then(res=>{
          this.courseDetailArr=res.data.records
          this.courseDetailArr.forEach((item,index)=>{
            let data={
              examPapersType:3,
              page:1,
              size:100,
              collegeEntranceSubjectId:item.id
            }
            collegeEntranceTemplateList(data).then(res=>{
              this.$set(this.courseDetailArr[index],  "children",res.data.records);
            })
          })

        })

      },
  //专业考试和单元测试选项卡
      changeExam(index){
        this.examShow=index;
        this.getCollegeTemplateList()
      },
    //  改变tab
      changeVocationNews(index){
        this.newsNavShow=index;
      },
    //  去综合测试列表
      goTopicList(){
        if(this.examShow==0){
          this.$router.push({path:'/topicList',query:{tabIndex:1}})
        }else{
          this.$router.push({path:'/topicList',query:{tabIndex:4}})
        }

      },
      //
      cityName(e){
        this.cityTitle=e[0];
        this.getNewsList()
      },
      //  获取新闻列表
      getNewsList(){
        let data={
          page:this.currentPage,
          size:this.pagesize,
          provinceName:''
        }
        if(this.cityTitle){
          data['provinceName']=this.cityTitle
        }
        newsList(data).then(res => {
          let arr1=[];
          let arr2=[];
          let arr3=[];
          let arr4=[];
          let arr5=[];
          res.data.records.forEach(item=>{
            item.createTime=item.createTime.substring(0, 10)
            if(item.newsType==3){
              arr1.push(item)
            }else if(item.newsType==4){
              arr2.push(item)
            }else if(item.newsType==5){
              arr3.push(item);
            }else if(item.newsType==6){
              arr4.push(item)
            }else if(item.newsType==7){
              arr5.push(item)
            };
          });
          this.newsListArr=res.data.records;
          this.newsListArr.forEach((item,index)=>{
            this.$set(this.newsListArr[0],  "colorShow",true);
            this.$set(this.newsListArr[1],  "colorShow",true);
            this.$set(this.newsListArr[2],  "colorShow",true);
          })

          this.headNewsList=arr1;
          this.policyInfoList=arr2;
          this.examinationUnscrambleList=arr3;
          this.referenceList=arr4;
          this.recruitNewsList=arr5;
        })

      },
    //  去更多列表页面
      goNewsmoreList(index){{
          let routeUrl = this.$router.resolve({
            path: "/messageList",
            query:{tabIndex:index}
          });
          window.open(routeUrl.href);
        }
      },
      //备考资讯,备考课程更多页面
      goNewsmoreListCourse(newsNavShow){
        if(newsNavShow == 0){
          let routeUrl = this.$router.resolve({
            path: "/messageList",
            query:{tabIndex:5}
          });
          window.open(routeUrl.href);
        }else if(newsNavShow==1){
          let routeUrl = this.$router.resolve({
            path: "/messageList",
            query:{tabIndex:4}
          });
          window.open(routeUrl.href);
        }
      },
    //  去新闻详情
      goNewsDetail(item){
        // this.$router.push({path:'informationDetail',query:{newsIndex:1}});
        sessionStorage.setItem('occupationNewsDetail',JSON.stringify(item));
        let routeUrl = this.$router.resolve({
          path: "/informationDetail",
          query:{newsIndex:1}
        });
        window.open(routeUrl.href);
      },
    //  获取备考课程列表
      getPreparingVideoList(){
        let data={
          page:1,
          size:2
        };
        preparingVideoList(data).then(res=>{
          this.VideoList=res.data.records
        })
      },
      //获取职教首页视频接口
      gethomeVideo(){
        let data={
          fileCode:'TEACH'
        };
        bannerList(data).then(res => {
          if(res.data.records&&res.data.records.length){
            let id=res.data.records[0].id
            this.filePath=res.data.records[0].filePath
            let quety={
              id:id
            };
            homeVideoInfo(quety).then(res=>{
              let newPlayAuth = {
                playauth:res.data.playAuth,
                vid:res.data.videoMeta.videoId,
                // autoplay:false,
              }
              this.videoOptions = newPlayAuth;
            })
          }
        })
      },
    //  去视频详情
      goVideoDetail(item){
        sessionStorage.setItem('videoDetails',JSON.stringify(item))
        let routeUrl = this.$router.resolve({
          path: "/courseVideo",
        });
        window.open(routeUrl.href);
      },
      //  获取职教高考平台试卷模板列表
      getCollegeTemplateList(){
        let data={
          examPapersType:2,
          page:1,
          size:10,
        }
        if(this.examShow==1){
          data.examPapersType=3
        }
        collegeEntranceTemplateList(data).then(res=>{
          this.collegeTemplateListArr=res.data.records
        })
      },
      //  综合试卷开始考试
      startExamination(item,exam){
        if(this.userInfo.classId){
          sessionStorage.setItem('examArrDetail',JSON.stringify(item))
          let routeUrl = this.$router.resolve({
            path: "/answerExam",
            query:{id:item.id,synthesis:1,userId:this.userInfo.id,examTypeShow:2}
          });
          window.open(routeUrl.href);
        }else{
          this.$alert('暂无权限，请加入班级后查看。', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.$message({
                type: 'info',
              });
            }
          });
        }
      },
    //  教师查看综合考试
      selectExamination(item){
        let routeUrl = this.$router.resolve({
          path: "/answerExam",
          query:{id:item.id,exam:'exam'}
        });
        window.open(routeUrl.href);
      },
      //  获取精品体系课
      getBoutiqueCourse(){
        let data={
          labels:'精品体系课',
        }
        courseList(data).then(res => {
          this.courserListArr=res.data.records
        })
      },
      //  去课程详情
      goCourseDetail(item) {
        item.collectListId=this.collectListId
        sessionStorage.setItem('courseDetail',JSON.stringify(item) )
        let routeUrl = this.$router.resolve({
          path: "/courseDetail",
        });
        window.open(routeUrl.href);
      },
      changeOpenVideo(){
        this.gethomeVideo();
        this.videoWhindow=true
      },
    },
    mounted() {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.$other.$emit('setIndexNav',3);
      this.getNewsList();
      this.gethomeVideo();
      this.getCollegeTemplateList()
      this.getBoutiqueCourse();
      this.getPreparingVideoList();
      this.getsubjectList()
    }
  }
</script>
<style>
@import "../../../public/css/vocationalExeam.css";
</style>
