<template>
  <div >
    <el-select clearable v-model="value" @change="changeCity()" placeholder="请选择省份">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.label"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
  export default{
    name:'city',
    data(){
      return{
        options: [
        {
          value: '1',
          label: '北京市'
        }, {
          value: '2',
          label: '天津市'
        }, {
          value: '3',
          label: '河北省'
        }, {
          value: '4',
          label: '山西省'
        }, {
          value: '5',
          label: '内蒙古自治区'
        }, {
          value: '6',
          label: '辽宁省'
        }, {
          value: '7',
          label: '吉林省'
        }, {
          value: '8',
          label: '黑龙江省'
        }, {
          value: '9',
          label: '上海市'
        }, {
          value: '10',
          label: '江苏省'
        }, {
          value: '11',
          label: '浙江省'
        }, {
          value: '12',
          label: '安徽省'
        }, {
          value: '13',
          label: '福建省'
        }, {
          value: '14',
          label: '江西省'
        }, {
          value: '15',
          label: '山东省'
        }, {
          value: '16',
          label: '河南省'
        }, {
          value: '17',
          label: '湖北省'
        }, {
          value: '18',
          label: '湖南省'
        }, {
          value: '19',
          label: '广东省'
        }, {
          value: '20',
          label: '广西壮族自治区'
        }, {
          value: '21',
          label: '海南省'
        }, {
          value: '22',
          label: '重庆市'
        }, {
          value: '23',
          label: '四川省'
        }, {
          value: '24',
          label: '贵州省'
        }, {
          value: '25',
          label: '云南省'
        }, {
          value: '26',
          label: '西藏自治区'
        }, {
          value: '27',
          label: '陕西省'
        }, {
          value: '28',
          label: '甘肃省'
        }, {
          value: '29',
          label: '青海省'
        }, {
          value: '30',
          label: '宁夏回族自治区'
        }, {
          value: '31',
          label: '新疆维吾尔自治区'
        }, {
          value: '32',
          label: '台湾省'
        }, {
          value: '33',
          label: '香港特别行政区'
        }, {
          value: '34',
          label: '澳门特别行政区'
        }],
        value: ''
      }
    },
    methods:{
      changeCity(){
        this.$emit('cityName', this.value)
      }
    }
  }
</script>
<style>

</style>
